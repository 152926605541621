import React from 'react';
import PianoLessonWide from '../images/Piano_Lesson_Wide.webp';
import GuitarBeingPlayedSquare from '../images/Guitar_Being_Played__Square.webp';
import GuitarBeingPlayedWide from '../images/Guitar_Being_Played_wide.webp';
import ReadingMusicWide from '../images/Reading_Music_Wide.webp';
import OrangeBassWide from '../images/Orange_Bass_Wide.webp';
import MicrophoneWide from '../images/Microphone_Wide.webp';
import BassBeingPlayedWide from '../images/Bass_Being_Played_Wide.webp';
import GuitarLiveWide from '../images/Guitar_Live_Wide.webp';
import DJMixingSquare from '../images/DJ_mixing__Square.webp'
import DJMixingWide from '../images/DJ_Mixing_Wide.webp'
import RecordingStudioWide from '../images/Recording_Studio_Wide.webp'
import ContactUsTelephone from '../images/ContactUs_Telephone.webp'
import PianoLesson2 from '../images/Piano_Lesson_2__Square.webp'
import DrumsBeingPlayed from '../images/Drums_Being_Played.webp';
import BrendanDuffy from '../images/Brendan Duffy.webp';
import DanJohnson from '../images/Dan Johnson.webp';
import MarietteHenningPugh from '../images/Mariette Henning-Pugh.webp';
import NurturingMinds from '../images/Nurturing Minds.webp';
import PlanB from '../images/Plan-B.webp';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import AboutIcon from '@mui/icons-material/MusicNote';
import ContactIcon from '@mui/icons-material/Email';
import TestimonialIcon from '@mui/icons-material/Star';
import InfoIcon from '@mui/icons-material/Info';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import GuitarIcon from '../images/guitar-24.png';
import KeyboardIcon from '../images/piano-24.png';
import DrumsIcon from '../images/drums-24.png';
import MMLogo from '../images/mm_logo_round.png';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import ChatIcon from '@mui/icons-material/Chat';
import EmailIcon from '@mui/icons-material/Email';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

export type PageInfoType = {
  id: number;
  showHero?: boolean;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  link: string;
  linkText: string;
  linkIcon: React.ReactNode;
  copy?: string;
  bgColour: string;
  textColour: string;
  pageBgColour?: string;
  sections: PageInfoTypeSection[];
  children?: SubPageInfoType[];
  cards: PageInfoTypeSection[];
  textListItems: PageInfoTypeSection[];
}


export type PageInfoTypeSection = {
  id: number;
  title?: React.ReactNode;
  copy: React.ReactNode;
  copyNode?: React.ReactNode;
  image: string | null;
  showQuotes?: boolean;
  icon?: React.ReactNode;
  button: ButtonDetailsType;
  name?: string;
  clickable?: boolean;
}

export type ButtonDetailsType = {
  link?: string;
  buttonText?: string;
}

export type SubPageInfoType = {
  id: number;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  ref: string;
  copy?: string;
  textColour?: string;
  sectionHighlightColour?: string;
  sections: PageInfoTypeSection[];
  cards?: PageInfoTypeSection[];
  textListItems?: PageInfoTypeSection[];
}

const pages: PageInfoType[] =
  [
    {
      id: 1,
      linkText: "Home",
      title: <Typography sx={{ textAlign: 'center', fontWeight: 600 }} variant='h3'>Music Matters</Typography>,
      subtitle:
        <Stack direction="column">
          <Typography variant="h5" textAlign="center" sx={{ fontWeight: 500 }}>Specialists in SEN/D music sessions</Typography>
          <Typography variant="h6" textAlign="center" sx={{ fontWeight: 500 }}>Because music should be for everybody</Typography>
          <Stack direction="row" spacing={5} justifyContent="center" sx={{ pt: 2, display: { xs: 'none', sm: 'flex' } }}>
            <Chip clickable component={RouterLink} to="/about/whats-on-offer" state={{ scrollTo: "guitar" }} label="Guitar" variant="outlined" avatar={<Avatar src={GuitarIcon} alt='Guitar' />} />
            <Chip clickable component={RouterLink} to="/about/whats-on-offer" state={{ scrollTo: "keyboards" }} label="Keyboards" variant="outlined" avatar={<Avatar src={KeyboardIcon} alt='Keyboards' />} />
            <Chip clickable component={RouterLink} to="/about/whats-on-offer" state={{ scrollTo: "drums" }} label="Drums" variant="outlined" avatar={<Avatar src={DrumsIcon} alt='Vocals' />} />
            <Chip clickable component={RouterLink} to="/about/whats-on-offer" label="See more" variant="outlined" icon={<MoreIcon />} />
          </Stack>
          <Stack direction="row" spacing={3} justifyContent="center" sx={{ pt: 2, display: { xs: 'none', sm: 'none' } }}>
            <RouterLink to="/about/whats-on-offer" state={{ scrollTo: "guitar" }}>
              <Avatar sx={{ width: 24, height: 24 }} src={GuitarIcon} alt="Guitar" />
            </RouterLink>
            <RouterLink to="/about/whats-on-offer" state={{ scrollTo: "keyboards" }}>
              <Avatar sx={{ width: 24, height: 24 }} src={KeyboardIcon} alt="Keyboards" />
            </RouterLink>
            <RouterLink to="/about/whats-on-offer" state={{ scrollTo: "drums" }}>
              <Avatar sx={{ width: 24, height: 24 }} src={DrumsIcon} alt="Guitar" />
            </RouterLink>
            <RouterLink to="/about/whats-on-offer">
              <Avatar sx={{ width: 24, height: 24, bgcolor: 'transparent' }} src={MMLogo} alt="About" />
            </RouterLink>
          </Stack>
        </Stack>,
      link: "/",
      linkIcon: <HomeIcon />,
      bgColour: "#1095D2",
      textColour: "#2b2a2d",
      pageBgColour: "#ffffff",
      sections: [],
      cards: [
        {
          id: 1,
          title: "About",
          image: null,
          copy: "Find out more about who we are and what we do.",
          icon: <InfoIcon />,
          button: {
            buttonText: "Find Out More",
            link: "/about"
          },
          clickable: true,
        },
        {
          id: 2,
          title: "Testimonials",
          image: PianoLesson2,
          copy: "Don't take our word for it, hear it from our students how much music means to them.",
          icon: <ChatIcon />,
          button: {
            buttonText: "Testimonials",
            link: "/testimonials"
          },
          clickable: true,
        },
        {
          id: 3,
          title: "Contact",
          image: ContactUsTelephone,
          copy: "Please contact us to find out more!",
          icon: <EmailIcon />,
          button: {
            buttonText: "Contact Us",
            link: "/contact"
          },
          clickable: true,
        },
      ],
      textListItems: [
        {
          id: 1,
          title: "Brand New Site!",
          copy: "Welcome to our new website, we will keep you updated with all our activities over 2023 and we look forward to making music matter with you all.",
          image: null,
          button: {},
          name: "Dan",
          clickable: true,
        }
      ]
    },
    {
      id: 2,
      linkText: "About us",
      title: <Typography sx={{ textAlign: 'center' }} variant='h3'>What we're all about</Typography>,
      subtitle:
        <Grid container justifyContent="center" sx={{ pt: 2 }}>
          <Grid item maxWidth="sm">
            <Typography variant="subtitle1"><em>I think music in itself is healing. It's an explosive expression of humanity. It's something we are all touched by. No matter what culture we're from, everyone loves music.</em><br />
              <Typography variant="body2" textAlign="right" sx={{ fontWeight: 700 }}> - Billy Joel</Typography>
            </Typography>
          </Grid>
        </Grid>,
      link: "/about",
      linkIcon: <AboutIcon />,
      bgColour: "rgba(255, 209, 11, 1)",
      textColour: "#2b2a2d",
      cards: [
        {
          id: 1,
          title: "About us",
          copy: "Let us introduce ourselves... Find out about our amazing staff!",
          image: null,
          showQuotes: true,
          button: {
            buttonText: "Check us out",
            link: "/about/about-us"
          },
          clickable: true,
        },
        {
          id: 2,
          title: "What we Offer",
          copy: "Whether it's instruments, vocals or behind-the-scenes, find out more about what we offer",
          image: GuitarBeingPlayedSquare,
          showQuotes: true,
          button: {
            buttonText: "Find Out More",
            link: "/about/whats-on-offer"
          },
          clickable: true,
        },
        {
          id: 3,
          title: "Our Patrons",
          copy: "The incredible people and organisations we work with to ensure you get the best that music has to offer.",
          image: GuitarBeingPlayedSquare,
          showQuotes: true,
          button: {
            buttonText: "Find Out More",
            link: "/about/our-patrons"
          },
          clickable: true,
        }
      ],
      textListItems: [],
      children: [
        {
          id: 1,
          title: "Music Matters: Our Story",
          subtitle:
            <Typography component="div">
              <Typography variant="body1" component="p">A little about us and the knowledge, experience and dedication we bring to teaching music to young SEN/D people and young people with mental health difficulties or challenging life circumstances</Typography>
              <Typography variant="subtitle1" component="p">Music matters is a non Profit org dedicated to helping young people with SEN/D mental health difficulties or challenging life circumstances.</Typography>
            </Typography>,
          ref: "about-us",
          sections: [
            {
            id: 1,
            title: <Typography component="div" variant="h5">Dan Johnson (Co-Founder)</Typography>,
            copy:
              <Typography>
                <Typography>Dan has been a musician for over twenty years. He has experience in multiple instruments and loves to teach. After working in schools and alternative education, he decided to make music matters to fill in the gaps and help the young people that would benefit from music the most. </Typography>
              </Typography>,
            image: DanJohnson,
            showQuotes: false,
            button: {},
          }
        ],
        },
        {
          id: 2,
          title: "What's on offer",
          subtitle: "Here's a selection of the things we offer",
          ref: "whats-on-offer",
          sections: [
            {
              id: 1,
              title: <Typography component="div" variant="h5" id="guitar">Guitar</Typography>,
              copy: "A great instrument for expressing yourself, the guitar offers young people the chance to unleash their inner rockstar.",
              image: GuitarLiveWide,
              showQuotes: false,
              button: {},
            }, {
              id: 2,
              title: <Typography component="div" variant="h5" id="bass">Bass</Typography>,
              copy: "The bass, similar to a guitar is a great instrument for expressing yourself. Some say it's easier than guitar, but they’ve probably never slapped the bass!",
              image: OrangeBassWide,
              showQuotes: false,
              button: {},
            }, {
              id: 3,
              title: <Typography component="div" variant="h5" id="keyboards">Keyboards</Typography>,
              copy: "Keyboards offer a huge scope for creativity. Once the fundamentals are grasped, we find the keyboards quite addictive. One of our young people went from being a grime artist to mastering fur Elise by Beethoven on our grand piano.",
              image: PianoLessonWide,
              showQuotes: false,
              button: {},
            }, {
              id: 4,
              title: <Typography component="div" variant="h5" id="drums">Drums</Typography>,
              copy: "Drums are the ultimate instrument to let out some frustration! as well as making plenty of noise, our young people find after a session on the drums they’re ready to wind down and relax.",
              image: DrumsBeingPlayed,
              showQuotes: false,
              button: {},
            }, {
              id: 5,
              title: <Typography component="div" variant="h5" id="vocals">Vocals</Typography>,
              copy: "When a student finds their 'voice' it is empowering. Wether it be singing, rapping, or the spoken word, we are here to help them unlock their inner voice. We offer sessions in both singing and songwriting.",
              image: MicrophoneWide,
              showQuotes: false,
              button: {},
            }, {
              id: 6,
              title: <Typography component="div" variant="h5" id="dj-experience">DJ experience</Typography>,
              copy: "Ever wanted to be a DJ in Ibiza? Then this is the starting point. Our popular DJ experience is a huge hit amongst young people.",
              image: DJMixingWide,
              showQuotes: false,
              button: {},
            }, {
              id: 7,
              title: <Typography component="div" variant="h5" id="mobile-recording-studio">Mobile Recording Studio</Typography>,
              copy: "This is the ultimate experience for young artists! We have a full mobile studio, capable of making any genre of track. From trap, to orchestral compositions. As well as a vocal recording booth! We can record vocals, rap, and everything in between. This is a huge hit with young people, they can even get some studio engineering sessions!",
              image: RecordingStudioWide,
              showQuotes: false,
              button: {},
            }
          ],
        },
        {
          id: 3,
          title: "Our Patrons",
          subtitle: "A selection of some of the people and organisations we work with to provide the best possible care and support",
          ref: "our-patrons",
          sections: [
            {
              id: 1,
              title: <Typography component="div" variant="h5">Plan B</Typography>,
              copy:
                <Typography>
                  <Typography>Working with young people from Hampshire and surrounding South East areas of the UK, the organisation will support young adults who are, for whatever reason, unable to engage with mainstream education. The organisation’s philosophy is simple. Plan A (mainstream education) is not working, so Plan B (alternative provision) must be different.</Typography>
                  <Link href='www.theplan-b.org' target='_blank'>www.theplan-b.org</Link>
                </Typography>,
              image: PlanB,
              showQuotes: false,
              button: {},
            }, {
              id: 2,
              title: <Typography component="div" variant="h5">Nurturing Minds Psychology</Typography>,
              copy:
                <Typography>
                  <Typography>Nurturing Minds Psychology is a service that provides expert professional psychological therapy and services to young people, adults and their families in Hampshire and the surrounding areas.</Typography>
                  <Link href='www.nurturingmindspsychology.com' target='_blank'>www.nurturingmindspsychology.com</Link>
                </Typography>,
              image: NurturingMinds,
              showQuotes: false,
              button: {},
            }, {
              id: 3,
              title: <Typography component="div" variant="h5">Brendan Duffy</Typography>,
              copy: "Brendan is a school counsellor for a local infant/junior school, he trained as a person-centred psychotherapist, but now employs elements of other humanistic therapies. This can include creative and play therapy. He has over a decade experience of working alongside children and young people with social, emotional and mental health difficulties. Brendan is BACP registered and works within their ethical framework.",
              image: BrendanDuffy,
              showQuotes: false,
              button: {},
            }, {
              id: 4,
              title: <Typography component="div" variant="h5">Dr Mariette Henning-Pugh</Typography>,
              copy: "Chartered Clinical Psychologist/Supervisor/ Specialist trainer, Mariette is a Chartered Clinical Psychologist registered with the HCPC, BPS, ACAT. Dr Mariette Henning-Pugh worked in the NHS for over 10 years. This included working in specialist services such as Child Development Centres, specialist CAMHS, in family court teams and teams working with children in care, adopted children, children living with carers under specialist guardianship orders and children who have been sexually traumatised, young offenders, unaccompanied asylum seeking children, as well as Neurodevelopmental teams for many years. Mariette has also been commissioned by a number of local authorities for assessments and therapeutic work, as well as working with regional adoption agencies and residential homes, education providers in a variety of ways.",
              image: MarietteHenningPugh,
              showQuotes: false,
              button: {},
            }
          ],
        },
      ],
      sections: [
        {
          id: 1,
          name: 'benefits',
          title: 'Benefits',
          copy: 'Helping young people with special educational needs, disabilities, mental health difficulties and otherwise challenging life circumstances reap the benefits music brings.',
          image: PianoLessonWide,
          showQuotes: true,
          button: {},
        },
        {
          id: 2,
          name: 'research',
          title: 'Research',
          copy: 'Research shows that music can be of great benefit. Here at music matters we believe that music should be accessible to everyone, so we are breaking down the barriers that come with learning an instrument and making music...',
          image: PianoLessonWide,
          showQuotes: true,
          button: {},
        },
        {
          id: 3,
          name: 'qualifications',
          title: 'Qualifications',
          copy: 'We believe that all students deserve the chance to gain qualifications in subjects they enjoy. Here at music matters we are able to offer Open Awards  qualifications in different aspects of music. We can also prepare students to sit their graded exams with ABRSM.',
          image: PianoLessonWide,
          showQuotes: true,
          button: {},
        },
        {
          id: 4,
          name: 'support',
          title: 'Support',
          copy: 'We work with a variety of partners to provide bespoke music projects to support children and young people with additional needs and disabilities to access high quality music making.',
          image: PianoLessonWide,
          showQuotes: true,
          button: {},
        },
        {
          id: 5,
          name: 'creativity',
          title: 'Creativity',
          copy: 'Working alongside schools and alternative education systems to deliver the highest quality sessions, Our young people feel the benefits of inclusion, confidence building, and finding their own creativity.',
          image: PianoLessonWide,
          showQuotes: true,
          button: {},
        },
        {
          id: 6,
          title: "Who is this for?",
          copy: "For children and young people with special educational needs or disabilities (SEN/D), mental health difficulties, or challenging life circumstances, research shows that music can be of great benefit.",
          image: PianoLessonWide,
          showQuotes: true,
          button: {},
        },
        {
          id: 7,
          title: null,
          copy: "Here at music matters we believe that music should be accessible to everyone, so we are breaking down the barriers that come with learning an instrument and making music...",
          image: GuitarBeingPlayedWide,
          showQuotes: true,
          button: {},
        },
        {
          id: 8,
          title: null,
          copy: "We work with a variety of partners to provide bespoke music projects to support children and young people with additional needs and disabilities to access high quality music making.",
          image: ReadingMusicWide,
          showQuotes: true,
          button: {},
        },
        {
          id: 9,
          title: null,
          copy: "Working alongside schools and alternative education systems to deliver the highest quality sessions, Our young people feel the benefits of inclusion, confidence building, and finding their own creativity.",
          image: BassBeingPlayedWide,
          showQuotes: true,
          button: {},
        }
      ]
    },
    {
      id: 3,
      linkText: "Testimonials",
      title: <Typography sx={{ textAlign: 'center' }} variant='h3'>Our Testimonials</Typography>,
      subtitle:
        <Grid container justifyContent="center" sx={{ pt: 2 }}>
          <Grid item maxWidth="sm">
            <Typography variant="h5" textAlign="center" sx={{ fontWeight: 500 }}>
              Don't just take our word for it! Take a look at what our students, past and present, have to say...
            </Typography>
          </Grid>
        </Grid>,
      link: "/testimonials",
      linkIcon: <TestimonialIcon />,
      bgColour: "rgba(253, 184, 37, 1)",
      textColour: "#2b2a2d",
      pageBgColour: "#ffffff",
      cards: [],
      textListItems: [],
      sections: [
        {
          id: 1,
          copy: "I started having sessions with Dan on guitar, which led to me wanting to learn music as a subject. I’ve learned some studio engineering and have started to learn the piano. I am now going on to study music at college",
          image: GuitarBeingPlayedSquare,
          title: "Alfie",
          showQuotes: true,
          button: {},
        }, {
          id: 2,
          copy: "Being the in the vocal booth freestyling is like therapy. I can let everything out.",
          image: DJMixingSquare,
          title: "Leo",
          showQuotes: true,
          button: {},
        }, {
          id: 3,
          copy: "Check back to see more testimonials soon!",
          image: null,
          title: "",
          showQuotes: true,
          button: {},
        }
      ],
    },
    {
      id: 5,
      linkText: "Contact us",
      showHero: true,
      title: <Typography sx={{ textAlign: 'center' }} variant='h3'>Get in touch</Typography>,
      subtitle:
        <Grid container justifyContent="center" sx={{ pt: 2 }}>
          <Grid item maxWidth="sm">
            <Typography variant="h5" textAlign="center" sx={{ fontWeight: 500 }}>
              Now that you've heard a little about us, we'd love to know more about you!
            </Typography>
          </Grid>
        </Grid>,
      link: "/contact",
      linkIcon: <ContactIcon />,
      bgColour: "rgb(149, 211, 250)",
      textColour: "#2b2a2d",
      sections: [
        {
          id: 1,
          copy: "We'd really like to speak to you and see how we can help!",
          image: null,
          title: "",
          showQuotes: false,
          button: {}
        },
        {
          id: 2,
          copy: "If you have any questions, queries or just want to talk please use the form below to get in touch...",
          image: null,
          title: "",
          showQuotes: false,
          button: {}
        },
        {
          id: 3,
          copy: "How we're helping young people with SEN/D, mental health difficulties, or challenging life circumstances gain access to the joy of music",
          image: null,
          title: "",
          showQuotes: false,
          button: {}
        },
        {
          id: 4,
          copy: "Your personal situation and experiences... Because we know everyone is unique!",
          image: null,
          title: "",
          showQuotes: false,
          button: {}
        },
        {
          id: 5,
          copy: "How we can help you or those you care for",
          image: null,
          title: "",
          showQuotes: false,
          button: {}
        },
        {
          id: 6,
          copy: "How you can help us!",
          image: null,
          title: "",
          showQuotes: false,
          button: {}
        },
      ],
      cards: [],
      textListItems: []
    },
  ]

export default pages;