import React, { useEffect, useState } from 'react';
import Container from "@mui/material/Container";
import { Outlet, useLocation } from 'react-router-dom';
import { Navigation, SiteHero } from '../components';
import Box from '@mui/material/Box';
import Pages, { PageInfoType } from '../data/page_data';
import { GlobalStyles, Toolbar, useTheme } from '@mui/material';
import Footer from '../components/Footer';
import HomeIcon from '@mui/icons-material/Home';

export default function Root(){

  const location = useLocation();
  const theme = useTheme();

  const defaults = React.useMemo(() => {
    return {
      id: 0,
      link: "/",
      linkText: "Home",
      linkIcon: <HomeIcon />,
      title: 'Music Matters',
      subtitle: '',
      showHero: true,
      bgColour: theme.palette.primary.main,
      textColour: theme.palette.text.primary,
      pageBgColour: theme.palette.background.default,
      sections: [],
      cards: [],
      textListItems: [],
      children: []
  }},[theme])

  const [pageInfo, setPageInfo] = useState<PageInfoType>(defaults);

  useEffect(() => {

    const currPathFragments = location.pathname.split("/");
    const parentPage = '/' + currPathFragments[1];

    const currentPage: PageInfoType[] = Pages.filter(page => page.link === parentPage );
    setPageInfo({
      id: currentPage[0].id ?? defaults.id,
      link: currentPage[0].link ?? defaults.link,
      linkText: currentPage[0].linkText ?? defaults.linkText,
      linkIcon: currentPage[0].linkText ?? defaults.linkIcon,
      title: currentPage[0].title ?? defaults.title,
      subtitle: currentPage[0].subtitle ?? defaults.subtitle,
      showHero: currentPage[0].showHero ?? defaults.showHero,
      bgColour: currentPage[0].bgColour ?? defaults.bgColour,
      textColour: currentPage[0].textColour ?? defaults.textColour,
      pageBgColour: currentPage[0].pageBgColour ?? defaults.pageBgColour,
      sections: currentPage[0].sections ?? defaults.sections,
      cards: currentPage[0].cards ?? defaults.cards,
      textListItems: currentPage[0].textListItems ?? defaults.textListItems
    });
  },[location, defaults]);

  return (
      <Box minHeight="1vh">
        <GlobalStyles styles={{body:  { backgroundColor: pageInfo.pageBgColour }}} />
        <span />
        <Navigation />
          { pageInfo.showHero ? (
            <SiteHero title={pageInfo.title} subtitle={pageInfo.subtitle} bgColour={pageInfo.bgColour} textColour={pageInfo.textColour} />
          ) : (
            <Toolbar />
          )}
          <Container maxWidth="xl" sx={{pt: {xs: 1, md: 3}, pb: {xs: 4, md: 10}, px: {xs: 1, overflowX: 'hidden'}}}>
            <Outlet />
          </Container>
          <Toolbar />
          <Footer bgColour={pageInfo.bgColour} textColour={pageInfo.textColour}></Footer>
      </Box>
  )
}