import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import socialLinks, { ISocialTypes, SocialLinkType } from '../data/social_feeds';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material';

function Footer(props: FooterProps) {

  const { bgColour, textColour } = props;

  const [socialMedia] = useState<SocialLinkType[]>(socialLinks);

  const bgImage1 = () => {
    return `<svg viewBox='0 0 1440 320' xmlns='http://www.w3.org/2000/svg'><path fill='${bgColour.replace('#', '%23')}' fill-opacity='0.8' d='M 0 95.273 L 60 84.573 C 120 74.273 240 52.273 360 41.973 C 480 31.273 600 31.273 720 52.573 C 840 74.273 965.285 96.946 1086.386 97.451 C 1214.368 97.985 1314.412 91.975 1375.21 75.1 L 1441.596 50.643 L 1440 320 L 1380 320 C 1320 320 1200 320 1080 320 C 960 320 840 320 720 320 C 600 320 480 320 360 320 C 240 320 120 320 60 320 L 0 320 L 0 95.273 Z'/></svg>`;
  }
  const bgImage2 = () => {
    return `<svg viewBox='0 0 1440 320' xmlns='http://www.w3.org/2000/svg'><path fill='${bgColour.replace('#', '%23')}' fill-opacity='0.5' d='M -0.798 116.521 C -1.262 117.078 122.901 159.447 203.725 162.236 C 312.461 157.263 320 144.127 472.816 119.682 C 625.632 95.237 803.962 79.64 964.789 80.171 C 1126.385 80.705 1239.291 136.887 1320.089 155.098 C 1368.989 168.142 1440.875 164.154 1440.798 164.131 L 1440 320 L 1360 320 C 1280 320 1120 320 960 320 C 800 320 640 320 480 320 C 320 320 160 320 80 320 L 0 320 L -0.798 116.521 Z'/></svg>`;
  }
  const bgImage3 = () => {
    return `<svg viewBox='0 0 1440 230' xmlns='http://www.w3.org/2000/svg'><path fill='${bgColour.replace('#', '%23')}' fill-opacity='0.4' d='M -3.193 55.562 C -0.337 64.131 54.735 68.139 90.333 53.603 C 134.178 40.639 118.875 42.498 181.996 31.57 C 300.579 11.04 318.226 8.857 438.226 27.057 C 558.226 45.6 697.908 78.875 816.586 56.303 C 985.278 24.219 1193.73 90.408 1255.477 93.022 C 1322.915 95.877 1440.118 71.27 1440 46.187 L 1440 230 L 1380 230 C 1320 230 1200 230 1080 230 C 960 230 840 230 720 230 C 600 230 480 230 360 230 C 240 230 120 230 60 230 L 0 230 L -3.193 55.562 Z' /></svg>`;
  }

  const year = () => {
    return new Date().getFullYear();
  }

  return (
    <Box
      className="footer-bg"
      sx={{
        backgroundImage: `url("data:image/svg+xml, ${bgImage1()}"), url("data:image/svg+xml, ${bgImage2()}"), url("data:image/svg+xml, ${bgImage3()}")`,
        textAlign: "center"
      }}
    >
      <Container maxWidth="lg" sx={{ pt: 2 }}>
        <Grid item xs={12}>
          <Stack direction="column" sx={{ display: { xs: "none", sm: "flex" } }}>
            <Typography component="h2" color={textColour} variant="subtitle1">&copy; {year()} THIS MUSIC MATTERS CIC</Typography>
          </Stack>
          <Stack spacing={1} direction="column" sx={{ display: { xs: "flex", sm: "none" } }}>
            <Typography
              component="h1"
              variant="subtitle2"
              color={textColour}
              textAlign="center"
            >
              &copy; {year()} THIS MUSIC MATTERS CIC
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="center" >
            {socialMedia.map((link: SocialLinkType) => (
              link.enabled && (
                <SocialLink key={link.id} link={link} />
              )
            ))}
          </Stack>
        </Grid>
      </Container>
    </Box>
  )
}

export type FooterProps = {
  bgColour: string;
  textColour: string;
}

function SocialLink(props: { link: SocialLinkType }) {

  const { link } = props;
  const theme = useTheme();

  return (
    <Tooltip title={link.tooltipText} arrow followCursor>
      <IconButton
        component="a"
        href={link.link}
        target="_blank"
        size="medium"
        aria-label={link.service}
        sx={{
          color: theme.social[link.service as keyof ISocialTypes].text,
          '&:hover': {
            bgcolor: theme.social[link.service as keyof ISocialTypes].hover.background,
            color: theme.social[link.service as keyof ISocialTypes].hover.text
          }
        }}
      >
        {link.icon}
      </IconButton>
    </Tooltip>
  )
}

export default Footer;