import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { getPage } from '../functions/text_functions';
import { PageInfoType } from '../data/page_data';
import InfoCard from '../components/InfoCard';
import Paper from '@mui/material/Paper';
import { Stack, Typography } from '@mui/material';
import TextList from '../components/TextList';


export default function Home() {

  const [pageInfo, setPageInfo] = useState<PageInfoType|null>(null);
  
  useEffect(() => {
    const setupPage = async() => {
      const response = await getPage(1);
      setPageInfo(response);
    }

    setupPage();
  }, []);
  return (
      <Grid sx={{pt:2}} width="md" container direction="row" justifyContent="space-evenly" alignItems="center">
        {
          pageInfo?.cards.map((card, index) => {
            return <InfoCard key={index} title={card.title} copy={card.copy} image={card.image} icon={card.icon} button={card.button} />
          })
        }
        {(pageInfo?.textListItems !== undefined && pageInfo?.textListItems.length > 0) && 
        <Stack direction="column" justifyContent="space-evenly" alignItems="center">
          <Typography variant="h4" sx={{pt: 4}}>Latest Updates</Typography>
          <Paper elevation={3} sx={{py: 2, m: 4}} >
            <TextList collection={pageInfo?.textListItems}/>
          </Paper>
        </Stack>
        }
      </Grid>
  )
}