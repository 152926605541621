import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import React from "react";
import { PageInfoTypeSection } from "../data/page_data";
import MMIcon from '../images/mm_logo_round.png';

function textList(props: { collection?: PageInfoTypeSection[] | undefined }) {

  const {collection} = props;

  return (
    <List sx={{ width: '100%' }}>

      {
        collection?.map((textListItem, index) => {
          return  (
            textListItem.id === -1 ? (
              <ListItem key={index}>
                <ListItemAvatar></ListItemAvatar>
                <ListItemText
                  primary='&nbsp;'
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        &nbsp;
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            ) : (
              <ListItem key={index} alignItems="flex-start" divider={index + 1 !== props.collection?.length} >
                <ListItemAvatar>
                  <Avatar 
                  alt={textListItem.name} 
                  src={textListItem?.image == null ? MMIcon : textListItem.image} />
                </ListItemAvatar>
                <ListItemText
                  primary={textListItem.title}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {textListItem.name}
                      </Typography>
                      {" - " + textListItem.copy}
                    </React.Fragment>
                  }
                />
              </ListItem>
            )
          )
        })
      }
    </List>
  )
}

export default textList;