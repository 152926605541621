import { createTheme } from '@mui/material/styles';

interface ISocialLink {
  text: string;
  hover: {
    text: string;
    background: string;
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    themecolours: {
      darkblue: string;
      lightblue: string;
      darkyellow: string;
      lightyellow: string;
    },
    chill?: {
      primary: {
        blue?: string;
        yellow?: string;
      };
    },
    sitenav: {
      text: {
        primary: string;
        hover: string;
        active: string;
      },
      bar: {
        background: string;
      }
    },
    social: {
      button: {
        hover: string;
      },
      Facebook: ISocialLink,
      Twitter: ISocialLink,
      Instagram: ISocialLink,
      WhatsApp: ISocialLink,
      Tiktok: ISocialLink
    };
  }

  interface ThemeOptions {
    themecolours: {
      darkblue: string;
      lightblue: string;
      darkyellow: string;
      lightyellow: string;
    },
    chill?: {
      primary: {
        blue?: string;
        yellow?: string;
      };
    },
    sitenav?: {
      text?: {
        primary: string;
        hover?: string;
        active?: string;
      },
      bar?: {
        background?: string;
      }
    },
    social?: {
      button: {
        hover: string;
      },
      Facebook: ISocialLink,
      Twitter: ISocialLink,
      Instagram: ISocialLink,
      WhatsApp: ISocialLink,
      Tiktok: ISocialLink
    }
  }
}

const theme = createTheme({
  palette: {
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    primary: {
      main: '#0048ae',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#1296d4',
    },
    text: {
      primary: '#2b2a2d',
      disabled: 'rgba(4,30,66,0.3)',
      secondary: '#2b2a2d',
    },
  },
  themecolours: {
    darkblue: '#0047AE',
    lightblue: '#1095D2',
    darkyellow: '#FDB825',
    lightyellow: '#FFD10B'
  },
  chill: {
    primary: {
      blue: "rgba(0, 72, 174, 0.7)",
      yellow: "rgba(255, 218, 54, 0.7)"
    }
  },
  sitenav: {
    text: {
      primary: '#f1f1f1',
      hover: '#ffffff',
      active: '#f1f1f1',
    }
  },
  social: {
    button: {
      hover: '#ffffff'
    },
    Facebook: {
      text: "#3b5998",
      hover: {
        text: "#ffffff",
        background: "#3b5998"
      }
    },
    Twitter: {
      text: "#1DA1F2",
      hover: {
        text: "#ffffff",
        background: "#1DA1F2"
      }
    },
    Instagram: {
      text: "#d6249f",
      hover: {
        text: "#ffffff",
        background: "#d6249f"
      }
    },
    WhatsApp: {
      text: "#075e54",
      hover: {
        text: "#ffffff",
        background: "#075e54"
      }
    },
    Tiktok: {
      text: "#075e54",
      hover: {
        text: "#ffffff",
        background: "#075e54"
      }
    }
  }
});

export default theme;